.message-wrapper{
  /*background-image: url(../assets/status/noconnection.png);*/
  height: 400px;
  background-position: cover;
  background-repeat: no-repeat;
}
#network-status-online {
    background: green;
    padding: 15px;
    border-radius: 5px;
    color: white;
  }
  
  #network-status-offline {
    background: red;
    padding: 15px;
    border-radius: 5px;
    color: white;
  }
  
  .no-network {
    font-weight: bold;
    color: red;
  }
  
  .available-network {
    font-weight: bold;
    color: green;
  }