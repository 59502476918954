/* SocialShare.css */
.socialShareContainer {
  font-family: "Arial", sans-serif;
  background-color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.SocialShare__network {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SocialShare__network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.SocialShare__network__share-button {
  cursor: pointer;
}

.SocialShare__network__share-button:hover:not(:active) {
  opacity: 0.75;
}

@media (max-width: 768px) {
  .socialShareContainer {
      justify-content: center;
  }

  .SocialShare__network {
      margin-bottom: 10px;
  }
}
